export const environment = {
	env: 'dev',
	apiUrl:'https://hbr-hm-api-gateway-service-dev.samf6gkxexb.eu-gb.codeengine.appdomain.cloud/observations/api/v1/',
	loginUrl:'https://hbr-hm-api-gateway-service-dev.samf6gkxexb.eu-gb.codeengine.appdomain.cloud/observations/api/v1/',
	apiKey:'e5471174-21cd-4ff6-9978-6d472ee9a6df',
							
	expectedEndPointIndicator:'7',
	astro_api:'https://hbr-hm-api-gateway-service-dev.samf6gkxexb.eu-gb.codeengine.appdomain.cloud/astro/api/v1/',
							
	astro_api_clientId:'70ce4d5f-cbbd-438a-adc6-3ced8197bab6',
	osr_api:'https://hbr-hm-api-gateway-service-dev.samf6gkxexb.eu-gb.codeengine.appdomain.cloud/osr/api/v1/',
	osr_api_clientId:'ded48db6-cafa-4e8a-97f1-277defcc6b61',
							
	mapPortalId:'d123c2b9be7c460984aba0c39e525442',
	localUrl:'http://localhost:8080/api/v1',
	isLogin: false,
							
	apiAuthUrl: 'https://hbr-hm-auth-helper-service-dev.samf6gkxexb.eu-gb.codeengine.appdomain.cloud/api/v1/auth/token',
              
	activeDirectoryClientId:'56ee968e-c737-4ea4-9fbc-2e32c351ea03',
	activeDirectoryTenantId:'30453998-4784-4b0e-bdb0-a8ba14eff494',
							
	activeDirectoryRedirectUrl:'https://hydrometeo.naiade-dev.portofrotterdam.com/auth',
	activeDirectoryResource:'api://a0d69b69-0891-486f-b296-ee0debb1bd64',
              
	title:'Aeolus 2.3 DEV', 
	rws_api:'https://hbr-hm-api-gateway-service-dev.samf6gkxexb.eu-gb.codeengine.appdomain.cloud/rws/api/v1/',
	rws_api_clientId:'ded48db6-cafa-4e8a-97f1-277defcc6b61',
                                                        
	watsonUrl:'https://q9wpcl.internetofthings.ibmcloud.com/dashboard/#/boards/',
	naiadeMapUrl:'https://portmaps.maps.arcgis.com/apps/webappviewer/index.html?id=6ed91dbcbd154f7e83819f59f6a0ca5e',
	alertsUrl:'https://ibmbmmgmt.eu-gb.mybluemix.net/index?subscriptionId=3c5a6fe4-5376-47bf-8fd2-df00b53da5ab&dashboard=ans.dashboard.alertviewer'
};
